import Vue from 'vue'
import Vuex from 'vuex'
import Api from '../includes/Api'
import axios from 'axios';
import auth from './modules/auth.js'
import searchFilter from "./modules/searchFilter";
import locationPermission from "./modules/locationPermission";
import VuexPersistence from 'vuex-persist';

//Mutations
export const MUTATION_LOGIN = 'SET_USER_DETAILS';
export const MUTATION_LOGOUT = 'CLEAR_USER_DETAILS';

export const MUTATION_MEM_LOGIN = 'SET_MEM_DETAILS';
export const MUTATION_MEM_LOGOUT = 'CLEAR_MEM_DETAILS';

export const MUTATION_GROUP_LOGIN = 'SET_GROUP_DETAILS';
export const MUTATION_GROUP_LOGOUT = 'CLEAR_GROUP_DETAILS';

export const MUTATION_LANDING_LOGIN = 'SET_LANDING_DETAILS';
export const MUTATION_LANDING_LOGOUT = 'CLEAR_LANDING_DETAILS';

export const MUTATION_SHOW_LOADING_SPINNER = '[mutations] show_loading_spinner';
export const MUTATION_HAS_RECOMMENDED_PLANS='MUTATION_HAS_RECOMMENDED_PLANS';
export const MUTATION_SET_ERROR = 'MUTATION_SET_ERROR';

//Actions
export const ACTION_APP_INIT = 'ACTION_APP_INIT';

Vue.use(Vuex)

// VuexPersistence in order to store vuex state permanently
const vuexStorage = new VuexPersistence({
  storage: window.localStorage
  // only save some module
  // if needed to filter: mutation => (mutation.type === 'AUTH_LOGOUT')
})

export default new Vuex.Store({
    state: {
        agent: null,
        member: null,
        group: null,
        landing: null,
        showLoading:false,
        hasRecommendedPlans:false,
        isError: false
    },
    getters: {
      agent(state) {
        return state.agent;
      },
      member(state) {
        return state.member;
      },
      group(state) {
        return state.group;
      },
      landing(state) {
        return state.landing;
      },
      isError(state) {
        return state.isError;
    },
      // To check rep auth state
      isRepAuthenticated: state => state.auth.isRepAuthenticated,
      getLandingInfo: state => state.auth.landingInfo,
      getLandingUrl: state => state.auth.landingUrl,
      getRepInfo: state => state.auth.repInfo,
      getGroupInfo: state => state.auth.groupInfo,
      isPremierPlanSelected: state => state.searchFilter.isPremierPlanSelected,
      isPremierPlanOnCart: state => state.searchFilter.isPremierPlanOnCart,
      getZipCode: state => state.locationPermission.zipCode,
      getContinueEnrollmentFlagState: state => state.auth.isContinueEnrollmentLandingPage,
      getAgentInfo: state => state.agent,
      isEmployeeEnrollment: state => state.auth.isEmployeeEnrollment,
    },
    mutations: {
        [MUTATION_HAS_RECOMMENDED_PLANS](state, data) {
          state.hasRecommendedPlans=data;
        },
        [MUTATION_LOGIN](state, data) {
            state.agent = data;
            axios.defaults.headers['agent_id'] = data.agent_id
            window.localStorage.setItem('AGENT_ID', data.agent_id);
            window.localStorage.setItem('agent_id', data.agent_id);
        },
        [MUTATION_LOGOUT](state) {
            state.agent = null;
            window.localStorage.removeItem('AGENT_ID');
            window.localStorage.removeItem('agent_id');
            window.localStorage.removeItem('USER_ID');
            window.localStorage.removeItem('agent_id');
        },
        [MUTATION_MEM_LOGIN](state, data) {
            state.member = data;
            axios.defaults.headers['uid'] = data.userid
            window.localStorage.setItem('USER_ID', data.userid);
        },
        [MUTATION_MEM_LOGOUT](state) {
            state.member = null;
            window.localStorage.removeItem('USER_ID');
        },
        [MUTATION_GROUP_LOGIN](state, data) {
            state.group = data;
            axios.defaults.headers['group_id'] = data.gid
            window.localStorage.setItem('group_id', data.gid);
        },
        [MUTATION_GROUP_LOGOUT](state) {
            state.group = null;
            window.localStorage.removeItem('group_id');
        },
        [MUTATION_SET_ERROR](state, isError) {
          state.isError = isError;
      },
        [MUTATION_LANDING_LOGIN](state, data) {
          state.landing = data;
          if (data.type == 'group') {
            state.group = data.data;
            if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
              window.localStorage.setItem('group_id', data.data.gid);
              if (!state.auth.landingInfo && !state.auth.repInfo && !state.auth.groupInfo && state.auth.isRepAuthenticated) {
                window.localStorage.setItem('agent_id', data.agent_id);
                window.localStorage.setItem('AGENT_ID', data.agent_id);
                window.localStorage.setItem('eprocess', 'applicant');
                window.localStorage.setItem('open_type', data.type);
              }
            } else {
              window.localStorage.setItem('group_id', data.group_id);
            }
          } else {
            // If landing page is route from default elite && agent code is entered case
            if (!state.auth.landingInfo) {
              state.agent = data.data;
              window.localStorage.setItem('agent_id', data.agent_id);
              window.localStorage.setItem('AGENT_ID', data.agent_id);
              window.localStorage.setItem('eprocess', 'applicant');
              window.localStorage.setItem('open_type', data.type);
            }
          }
          // Check condition to check landingInfo value is null or not otherwise throws error.
          if (state.auth.landingInfo) {
            // If landing page is route from group && has agent case
            if (state.auth.landingInfo.type === 'group' && state.auth.repInfo) {
              state.agent = state.auth.repInfo
              window.localStorage.setItem('agent_id', state.auth.repInfo.agent_id);
              window.localStorage.setItem('AGENT_ID', state.auth.repInfo.agent_id);
              window.localStorage.setItem('eprocess', 'applicant');
              window.localStorage.setItem('open_type', data.type);
            } else if (state.auth.landingInfo.type === 'agent') {
              // If landing page is route from agent && has agent case.
              state.agent = state.auth.repInfo
              window.localStorage.setItem('agent_id', data.agent_id);
              window.localStorage.setItem('AGENT_ID', data.agent_id);
              window.localStorage.setItem('eprocess', 'applicant');
              window.localStorage.setItem('open_type', data.type);
            } else {
              // If landing page is route from group && has own group member employee (Default group case).
              window.localStorage.setItem('agent_id', data.agent_id);
              window.localStorage.setItem('AGENT_ID', data.agent_id);
              window.localStorage.setItem('eprocess', 'applicant');
              window.localStorage.setItem('open_type', data.type);
            }
          }

          if (location.host == process.env.VUE_APP_SITE_HOST_NAME && data.type == 'group') {
            window.localStorage.setItem('enroll_type', 'new-group-member');
          } else {
            window.localStorage.setItem('enroll_type', 'new-member');
          }
        },
        [MUTATION_LANDING_LOGOUT](state) {
            state.landing = null;
            state.group = null;
            state.agent = null;
            window.localStorage.removeItem('agent_id');
            window.localStorage.removeItem('AGENT_ID');
            window.localStorage.removeItem('eprocess');
            window.localStorage.removeItem('open_type');
            window.localStorage.removeItem('enroll_type');
            window.localStorage.removeItem('group_id');
        },
        [MUTATION_SHOW_LOADING_SPINNER](state,payload){
            state.showLoading = payload;
        },

      // default vuex persist mutation
      // this mutation **MUST** be named "RESTORE_MUTATION",
      RESTORE_MUTATION: vuexStorage.RESTORE_MUTATION
    },

    actions: {
        [ACTION_APP_INIT]({commit}, app) {
            if((location.host !== 'qa-enroll.purenroll.com' && location.host !== 'enroll.purenroll.com') && (location.hostname !== 'localhost') && (location.host !== process.env.VUE_APP_SITE_HOST_NAME)){
                Api.get('/get-domainSite-infromation?site_domain=' + window.btoa(unescape(encodeURIComponent( location.host )))).then((response)=> {
                    if(response.data.statusCode == 200) {
                        window.localStorage.setItem('eprocess', 'group');
                        commit(MUTATION_GROUP_LOGIN, response.data.data);
                        window.localStorage.setItem('AGENT_ID', response.data.data.gagent_code);
                        window.localStorage.setItem('agent_id', response.data.data.gagent_code);
                        window.localStorage.setItem('group_id', response.data.data.gid);
                        window.localStorage.setItem('portal_id', response.data.data.gid);
                        window.localStorage.setItem('enroll_type', 'new-group-member');
                        window.localStorage.setItem('open_type', 'user');
                        window.localStorage.setItem('eprocess', 'group');
                        app.$router.push('/home');
                    }
                    else if(response.data.statusCode == 204){
                        app.$router.push('/404');
                    }
                }).catch(() => {
                    app.$router.push('/404');
                }).then(() => {
                    app.$mount('#app');
                });
            }
            else {
              app.open_type = window.localStorage.getItem('open_type');
              app.continue_track = window.localStorage.getItem('continue_track');
              let landingParams = window.location.pathname.slice(1)
              if (landingParams) {
                Api.get('/validate-landing-page?landing_page=' + landingParams).then(function (response) {
                  if (response.data.status == 'success') {
                    app.$store.dispatch("isRepAuthenticated", false);
                    app.$store.dispatch("landingInfo", null);
                    app.$store.dispatch("landingUrl", null);
                    app.$store.dispatch("repInfo", null);
                    app.$store.dispatch("groupInfo", null);
                    app.$store.dispatch('landingUrl', landingParams)
                    app.$store.dispatch("isPremierPlanSelected", false);
                    app.$store.dispatch("isPremierPlanOnCart", false);
                    app.$store.dispatch("zipCode", null);
                    app.$router.push({name: 'AccessPage'})
                  }
                }).catch(() => {});
              } else {
                console.log('route params', app.$store.getters.getLandingUrl)
                app.$route.params.landing = app.$store.getters.getLandingUrl
              }
              if (location.host  == process.env.VUE_APP_SITE_HOST_NAME && app.open_type !== 'agent' && app.open_type !== 'group' && app.open_type == null && !app.$route.params.landing && app.$store.getters.isRepAuthenticated) {
                localStorage.clear();
                Api.get('/get-domainSite-infromation?site_domain=' + window.btoa(unescape(encodeURIComponent(  location.host )))).then((response)=> {
                  if(response.data.statusCode == 200) {
                    window.localStorage.setItem('eprocess', 'group');
                    commit(MUTATION_GROUP_LOGIN, response.data.data);
                    window.localStorage.setItem('AGENT_ID', response.data.data.gagent_code);
                    window.localStorage.setItem('agent_id', response.data.data.gagent_code);
                    window.localStorage.setItem('group_id', response.data.data.gid);
                    window.localStorage.setItem('portal_id', response.data.data.gid);
                    window.localStorage.setItem('open_type', 'user');
                    window.localStorage.setItem('enroll_type', 'new-group-member');
                  }
                }).catch(() => {
                  app.$router.push('/404');
                }).then(() => {
                  app.$mount('#app');
                });
              } else if (app.$route.name === 'error') {
                app.$mount('#app');
              } else if (app.$route.name === 'continue-enrollment') {
                localStorage.clear();
                app.$mount('#app');
              } else if (app.$route.name === 'direct-continue') {
                localStorage.clear();
                app.$mount('#app');
              } else if (app.$route.name === 'decline-reminder-email') {
                app.$mount('#app');
              } else if (app.$route.name === 'external') {
                if (location.host  == process.env.VUE_APP_SITE_HOST_NAME) {
                  app.$router.push('/');
                } else {
                  Api.get('/get-external-site-infromation?site_name=' + app.$route.query.site).then((response) => {
                    if (response.data.statusCode == 200) {
                      window.localStorage.setItem('eprocess', 'group');
                      commit(MUTATION_GROUP_LOGIN, response.data.data);
                      window.localStorage.setItem('AGENT_ID', response.data.data.gagent_code);
                      window.localStorage.setItem('agent_id', response.data.data.gagent_code);
                      window.localStorage.setItem('group_id', response.data.data.gid);
                      window.localStorage.setItem('portal_id', response.data.data.gid);
                      window.localStorage.setItem('enroll_type', 'new-group-member');
                      window.localStorage.setItem('eprocess', 'group');
                    } else if (response.data.statusCode == 204) {
                      app.$router.push('/404');
                    }
                  }).catch(() => {
                    app.$router.push('/404');
                  }).then(() => {
                    app.$mount('#app');
                  });
                }
              } else if (app.$route.name === 'referrals') {
                if (location.host  == process.env.VUE_APP_SITE_HOST_NAME) {
                  app.$router.push('/');
                } else {
                  let urlParams = new URLSearchParams(window.location.search);
                  if (urlParams.has('rid')) {
                    let rid = urlParams.get('rid');
                    Api.get('/get-enrollment-info-referral?referral_id=' + rid).then((response) => {
                      if (response.data.statusCode == 200) {
                        localStorage.clear();
                        window.localStorage.setItem('agent_id', response.data.data.agent_id);
                        window.localStorage.setItem('AGENT_ID', response.data.data.agent_id);
                        window.localStorage.setItem('group_id', response.data.data.group_id);
                        window.localStorage.setItem('eprocess', 'applicant');
                        window.localStorage.setItem('portal_id', response.data.data.portal_id);
                        window.localStorage.setItem('enroll_type', 'new-group-member');
                        window.localStorage.setItem('open_type', 'user');
                        window.localStorage.setItem('referral', rid);
                        window.localStorage.setItem('r_valid', response.data.data.valid_referral_link);
                        window.localStorage.setItem('r_name', response.data.data.referrer_info);
                        console.log(response.data.data);
                      }
                    }).catch(() => {
                      app.$router.push('/404');
                    }).then(() => {
                      app.$mount('#app');
                    });
                  } else {
                    app.$router.push('/404');
                  }
                }
              } else if (app.$route.name === 'websites') {
                if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
                  app.$router.push('/');
                } else {
                  let urlParams = new URLSearchParams(window.location.search);
                  if (urlParams.has('group')) {
                    let group_id_url = urlParams.get('group');
                    let group_id = group_id_url;
                    if (group_id === 'elevate' && urlParams.has('id')) {
                      window.localStorage.setItem('tempId', urlParams.get('id'));
                      window.localStorage.setItem('fromSite', 'elevate');
                      Api.post('/track-elevate-traffic', {enrollment_id: urlParams.get('id')}).then((response) => {
                        if (response.data.statusCode == 200) {
                          console.log('traffic tracked');
                        }
                      });
                    }
                    Api.get('/validate-landing-page?landing_page=' + group_id).then((response) => {
                      if (response.data.statusCode == 200) {
                        console.log(response.data.data);
                        commit(MUTATION_LANDING_LOGIN, response.data.data);
                      }
                    }).catch(() => {
                      commit(MUTATION_LANDING_LOGOUT);
                      app.$router.push('/404');
                    }).then(() => {
                      app.$mount('#app');
                    });
                  }
                }
              } else if (app.$route.name === 'main' || ((app.open_type !== null && app.open_type !== '' && app.open_type !== undefined) && (app.$route.name !== 'member home' && app.$route.name !== 'agent home' && app.$route.name !== 'group home' && app.$route.name !== 'referrals'))) {
                if (app.$route.params.landing  && app.$route.name === 'main') {
                  localStorage.clear();
                  Api.get('/validate-landing-page?landing_page=' + app.$route.params.landing).then((response) => {
                    if (response.data.statusCode == 200) {
                      console.log(response.data.data);
                      commit(MUTATION_LANDING_LOGIN, response.data.data);
                    }
                  }).catch(() => {
                    commit(MUTATION_LANDING_LOGOUT);
                    app.$router.push('/404');
                  }).then(() => {
                    if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
                      app.$bus.$emit('updateLandingType')
                    }
                    app.$mount('#app');
                  });
                } else {
                  if ((app.continue_track !== 'yes' && app.continue_track !== null) || window.localStorage.getItem('open_type') === 'agent' || window.localStorage.getItem('open_type') === 'group' || window.localStorage.getItem('open_type') === 'user') {
                    app.$mount('#app');
                  } else {
                    if (app.continue_track === 'yes') {
                      app.$mount('#app');
                    } else {
                      if (location.host != process.env.VUE_APP_SITE_HOST_NAME) {
                        localStorage.clear();
                        Api.get('/get-default-info').then((response) => {
                          if (response.data.statusCode == 200) {
                            console.log(response.data.data);
                            commit(MUTATION_LANDING_LOGIN, response.data.data);
                            window.localStorage.setItem('agent_id', response.data.data.agent_id);
                            window.localStorage.setItem('AGENT_ID', response.data.data.agent_id);
                            window.localStorage.setItem('group_id', response.data.data.gid);
                            window.localStorage.setItem('eprocess', 'applicant');
                            window.localStorage.setItem('portal_id', response.data.data.portal_id);
                            window.localStorage.setItem('enroll_type', 'new-group-member');
                            window.localStorage.setItem('open_type', 'user');
                          }
                        }).catch(() => {
                        }).then(() => {
                          app.$mount('#app');
                        });
                      }
                    }
                  }
                }
              } else {
                //Check in Local storage
                if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
                  app.$router.push('/');
                  app.$mount('#app');
                } else {
                  let agent_id = window.localStorage.getItem('AGENT_ID');
                  let load_agent = false;
                  if (agent_id) {
                    axios.defaults.headers['agent_id'] = agent_id;
                  }
                  let uid = window.localStorage.getItem('USER_ID');
                  if (uid) {
                    axios.defaults.headers['uid'] = uid;
                  }
                  let group_id = window.localStorage.getItem('group_id');
                  let load_group = false;
                  if (group_id) {
                    axios.defaults.headers['group_id'] = group_id;
                  }
                  //Check in urls - entry point
                  let urlParams = new URLSearchParams(window.location.search);
                  let load_member = false;
                  if (urlParams.has('agent_id')) {
                    let agent_id_url = urlParams.get('agent_id');
                    agent_id = agent_id_url;
                  }
                  if (urlParams.has('group')) {
                    let group_id_url = urlParams.get('group');
                    group_id = group_id_url;
                  }

                  if (urlParams.has('uid')) {
                    let mem_id_url = urlParams.get('uid');
                    uid = mem_id_url;
                  }
                  if (urlParams.has('group_id')) {
                    let group_id_url = urlParams.get('group_id');
                    group_id = group_id_url;
                  }

                  app.eprocess = window.localStorage.getItem('eprocess');
                  //Mount app only when the Agent Details available
                  if (agent_id == '' || agent_id == null || agent_id == undefined) {
                    // we should allow mount for saved enrollments - agent id will come later
                    if (app.$route.name == 'continue-enrollment') {
                      app.$mount('#app');
                    }
                  } else {
                    if (app.$route.name == 'agent home') {
                      localStorage.clear();
                      group_id = '';
                      load_agent = true;
                    }
                    if (app.$route.name !== 'group home' && app.$route.name !== 'member home') {
                      if ((app.eprocess === '' || app.eprocess === null || app.eprocess === undefined)) {
                        load_agent = true;
                      } else {
                        if (app.eprocess === 'rep') {
                          load_agent = true;
                        }
                      }
                    }
                    if (load_agent) {
                      Api.get('/get-agent-info-enroll?agent_id=' + agent_id).then((response) => {
                        if (response.data.data) {
                          window.localStorage.setItem('eprocess', 'rep');
                          commit(MUTATION_LOGIN, response.data.data);
                        }
                      }).catch(() => {
                        commit(MUTATION_LOGOUT);
                      }).then(() => {
                        app.$mount('#app');
                      });
                    }
                  }

                  if (uid == '' || uid == null || uid == undefined) {
                    //do nithing
                  } else {
                    if (app.$route.name == 'member home') {
                      localStorage.clear();
                      load_member = true;
                      group_id = '';
                    }
                    if (app.$route.name !== 'group home' && app.$route.name !== 'agent home') {
                      if ((app.eprocess === '' || app.eprocess === null || app.eprocess === undefined)) {
                        load_member = true;
                      } else {
                        if (app.eprocess === 'applicant') {
                          load_member = true;
                        }
                      }
                    }
                    if (load_member) {
                      Api.get('/get-member-info?user_id=' + uid).then((response) => {
                        if (response.data.data) {
                          window.localStorage.setItem('eprocess', 'applicant');
                          commit(MUTATION_MEM_LOGIN, response.data.data.member_info);
                          window.localStorage.setItem('AGENT_ID', response.data.data.agent_info.agent_id);
                          window.localStorage.setItem('agent_id', response.data.data.agent_info.agent_id);
                          window.localStorage.setItem('group_id', response.data.data.group_info.gid);
                          window.localStorage.setItem('portal_id', response.data.data.portal_id);

                        }
                      }).catch(() => {
                        commit(MUTATION_MEM_LOGOUT);
                      }).then(() => {
                        app.$mount('#app');
                      });
                    }
                  }

                  if (group_id == '' || group_id == null || group_id == undefined) {
                    //do nothing
                  } else {
                    if (app.$route.name == 'group home') {
                      localStorage.clear();
                      load_group = true;
                    }
                    if (app.$route.name !== 'agent home' && app.$route.name !== 'member home') {
                      if ((app.eprocess === '' || app.eprocess === null || app.eprocess === undefined)) {
                        load_group = true;
                      } else {
                        if (app.eprocess === 'group') {
                          load_group = true;
                        }
                      }
                    }

                    if (load_group) {
                      window.localStorage.setItem('group_id', group_id);
                      Api.get('/get-group-info-enroll?group_id=' + group_id).then((response) => {
                        if (response.data.data) {
                          window.localStorage.setItem('eprocess', 'group');
                          commit(MUTATION_GROUP_LOGIN, response.data.data);
                          // if (agent_id === '' || agent_id === null || agent_id === undefined) {
                          window.localStorage.setItem('AGENT_ID', response.data.data.gagent_code);
                          window.localStorage.setItem('agent_id', response.data.data.gagent_code);
                          // }
                          window.localStorage.setItem('group_id', response.data.data.gid);
                        }
                      }).catch(() => {
                        commit(MUTATION_GROUP_LOGOUT);
                      }).then(() => {
                        app.$mount('#app');
                      });
                    }
                  }
                }

              }
          }
    },
    },
    modules: {
      auth,
      searchFilter,
      locationPermission
    },
    plugins: [vuexStorage.plugin]

})
